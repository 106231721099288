export const gridItemBase = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100%",
    px: 0,
    background: {
        xs: "transparent linear-gradient(181deg, #200340 0%, #1C163F 50%, #7011A7 100%) 0% 0% no-repeat padding-box",
        md: "transparent linear-gradient(122deg, #1e003e 0%, #1c163f 50%, #7011a7 100%) 0% 0% no-repeat padding-box",
    },
    fontFamily: "Poppins-Regular",
};

export const gridContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "20px",
    paddingBottom: "20px",
};

export const gridResponsive = {
    mt: { xs: 65, sm: 132, md: 0 },
    display: { xs: "none", md: "block" },
};

export const logoContainer = {
    mb: 6,
    display: { xs: "none", md: "block" },
};

export const titleContainer = {
    display: { xs: "none", md: "flex" },
    flexDirection: "column",
    alignItems: "start",
    mt: { xs: 3 },
};

export const typographyTitleOne = {
    fontSize: { xs: 36, md: 38 },
    color: "white",
    lineHeight: 1.2,
    fontFamily: "Poppins-Medium",
    textAlign: { xs: "center", md: "start" },
};

export const typographyTitleTwo = {
    fontSize: { xs: 40, md: 90 },
    color: "#a361ff",
    lineHeight: 1,
    fontFamily: "Poppins-Bold",
    textAlign: "start",
};

export const typographyTitleThree = {
    fontSize: { xs: 40, md: 90 },
    color: "white",
    lineHeight: 1,
    fontFamily: "Poppins-SemiBold",
    textAlign: "start",
};

export const typographyGB = {
    fontSize: 90,
    fontFamily: "Poppins-Bold",
    lineHeight: 1.2,
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
};

export const typographyGBSpan = {
    fontSize: 20,
    top: 35,
    position: "relative",
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
};

export const typographyPlusGB = {
    fontSize: 22,
    lineHeight: 1.2,
    fontFamily: "Poppins-SemiBold",
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
};

export const boxContainerImg = {
    textAlign: "right",
    p: 0,
    mr: { xs: -2.8, md: -3 },
    mt: -5,
    mb: -6,
    display: { xs: "none", md: "block" },
};

export const boxContainerResponsiveImg = {
    my: 3,
    display: { xs: "flex", md: "none" },
};

export const TypographyValidity = {
    fontSize: 17,
    fontFamily: "Poppins-Medium",
    mb: 3,
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
};

export const boxContainerNoShareAndShareWifi = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
    mb: "16px",
};

export const typographyNoShareAndShareWifi = {
    fontSize: 14,
    color: "white",
    fontWeight: 500,
};

export const boxContainerMinAndSMS = {
    display: "flex",
    justifyContent: "center",
    gap: 1,
    alignItems: "center",
    mb: "21px",
    fontSize: 14,
};

export const buttonComponent = {
    mt: "24px",
    mb: "31px",
};

export const typographyLabel = {
    fontSize: 12,
    textAlign: "center",
};

export const inputStyle = {
    "& .MuiOutlinedInput-root": {
        background: "#ffffff",
        color: "#000",
        borderRadius: "5px",
        height: "55px",
        "&.Mui-focused": {
            color: "#e9e9e9",
        },
        "&:hover:not(.Mui-focused)": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#A361FF",
            },
        },
        "& input::placeholder": {
            color: "#43414A",
            fontSize: "15px",
        },
        "& input": {
            color: "#000",
        },
    },
};

export const typographyIMEI = {
    fontSize: 12,
    color: "#fff",
    width: "100%",
    display: "flex",
    gap: "3px",
    alignItems: "center",
    mb: 1,
};

export const iconArrowBack = {
    fontSize: 27,
    color: "white",
    mb: { xs: 1, md: 1 },
    mt: 0,
    "&:hover": {
        color: "#a361ff",
        backgroundColor: "transparent",
        fontWeight: 600,
    },
};
